import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import jsPDF from 'jspdf';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiciosService } from '../../../services/admin/servicios.service';

@Component({
  selector: 'app-estudios-id',
  templateUrl: './estudios-id.component.html',
  styleUrls: ['./estudios-id.component.css']
})
export class EstudiosIdComponent implements OnInit {

  public titulo = 'BITACORA DE RESULTADOS LABORATORIOS'
  public titulo2 = 'BITACORA DE RESULTADOS ULTRASONIDOS'
  public id = '';
  public paciente:any;
  public estudiosLab:any[]=[];
  public estudiosUsg:any[]=[];
  public metodo: any;
  public nombre: any;
  public examen: any;
  public valoresRef: any;
  public venta: any;
  public servicio: any;
  public resultados: any;
  public imgQrCode: any;
  public servicioValid = "";
  public curp = "";
  public solicitio2: any;
  public fechaPedidoLabs = "";
  public tomaxD : any;
  public liberacionxD : any;
  public muestraxD : any;
  public observacionesxD? : any;
  public realizoxD : any;
  public obtenidosFinalesHTML:any[] = [];
  public pedidoEstudio :any [] = [];
  public horaTomaPdf : any;
  public fechaToma = '';
  public horaLiberar : any;
  public fechaLiberacion = '';
  public impri= {
    grupo: '',
    elementos: '',
    obtenidos: '',
    unidad: '',
    valoresReferencia: ''
  }
  public noSe = [];
  public horaImpresion = moment().format('LT');
  public nombreServicio = '';
  public observacionesUSG = "";
  public imgUsg = [];
  public diagnosticoUsg = "";
  public interpretacion = "";
  public titleUsg = "";
  public solicitio ="";
  public fechaPedido = "";
  public idPaciente = "";
  public imgsUltra: any;
  public cedulaUSG = "";
  public especialidadUSG = "";

  constructor(private _route: ActivatedRoute,
              private _servicios: ServiciosService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id');
    this.paciente = JSON.parse(localStorage.getItem('paciente'));
    this.obtenerEstudios();
  }

  obtenerEstudios(){
    this.spinner.show();
    this._servicios.obtenerPedidoPorIdPaciente(this.id).subscribe((resp:any)=>{
      this.setEstudiosFiltro(resp['data']);
    })
  }

  setEstudiosFiltro(estudios:any){
    estudios.forEach(element => {
      if(element.nombreServicio == 'LABORATORIO'){
        this.estudiosLab.push(element);
      }else if(element.nombreServicio == 'ULTRASONIDO'){
        this.estudiosUsg.push(element);
      }
    });
    this.spinner.hide()
  }

  obtenerVAlores(item){
    this.nombreServicio = item.nombreServicio
    this.spinner.show();
    this._servicios.getResultadoPorEstudio(item._id).subscribe((resp:any)=>{
      if (resp.ok) {
        this.imprimir(resp);
      }
    })
  }

  imprimir(data:any){
    console.log(data);
    
    this.obtenidosFinalesHTML = [];
    this.paciente = data['data']['paciente']
    this.metodo = data['data']['metodo']
    this.nombre = data['data']['nombre']
    this.examen = data ['data']['tipo_de_examen']
    this.valoresRef = data ['data']['valoresDeReferencia']
    this.venta = data ['data']['venta']
    this.servicio = data['data']['servicio']
    this.imgQrCode = data['data']['imgQrCode']
    this.servicioValid = data['data']['nombreEstudio']
    this.curp = data['data']['paciente']['curp']
    this.solicitio2 = data['data']['solicito'];
    this.fechaPedidoLabs = data['data']['fechaPedido'];
    if( this.servicioValid != 'LABORATORIO' ) {
      // console.log("Entra");
      this.observacionesUSG = data['data']['resultados'][0].obtenidos.observaciones
      this.imgUsg = data['data']['resultados'][0].obtenidos.imgs
      this.diagnosticoUsg = data['data']['resultados'][0].obtenidos.diagnostico
      this.interpretacion = data['data']['resultados'][0].obtenidos.interpretacion
      this.titleUsg = data['data']['servicio']['ESTUDIO'];
      this.solicitio = data['data']['solicito'];
      this.fechaPedido = data['data']['fechaPedido'];
      this.realizoxD = data['data']['resultados'][0]['obtenidos']['realizo']
      this.cedulaUSG = data['data']['resultados'][0]['obtenidos']['cedula']
      this.especialidadUSG = data['data']['resultados'][0]['obtenidos']['especilidad']
    }else {
      this.resultados = data['data']['resultados'][0].obtenidos
      this.tomaxD = data['data']['resultados'][0].toma
      this.liberacionxD = data['data']['resultados'][0].liberacion
      this.muestraxD = data['data']['resultados'][0].muestra
      this.observacionesxD = data['data']['resultados'][0].observaciones
      this.realizoxD = data['data']['resultados'][0].realizo
      this.cedulaUSG = data['data']['resultados'][0]['obtenidos']['cedula']
      this.especialidadUSG = data['data']['resultados'][0]['obtenidos']['especilidad']
      //  si es de laboratorio
      for (const key in this.resultados) {
        this.obtenidosFinalesHTML.push(this.resultados[key])
      }
      this.pedidoEstudio = data['data']
      this.setEstudios(this.valoresRef, this.obtenidosFinalesHTML);
      this.horaTomaPdf = this.tomaxD.slice(11, 17);
      this.fechaToma = this.tomaxD.slice(0, 10);
      this.horaLiberar = this.liberacionxD.slice(11, 17)
      this.fechaLiberacion = this.liberacionxD.slice(0, 10)
    }
    if(this.nombreServicio.toLocaleLowerCase() == 'laboratorio'){
      this.testLabsPDF();
    }else if(this.nombreServicio.toLocaleLowerCase() == 'ultrasonido'){
      this.btnPrintPdf();
    }
  }

  setEstudios(data, obtenidosFinalesHTML) {
    let i= 0;
    this.noSe = [];
    for (let element of data ) {    
      this.impri.grupo = element.grupo;
      this.impri.elementos = element.elemento;
       this.impri.obtenidos= obtenidosFinalesHTML[i];

      this.impri.unidad = element.unidad;
      let val = ""
      element.valorDeReferencia.forEach(elem => {
        val = val.concat(elem.valorReferenciaD, '\n')
      });
      this.impri.valoresReferencia = val

      this.noSe.push(this.impri)
      this.impri = {
        grupo: '',
        elementos: '',
        obtenidos: '',
        unidad: '',
        valoresReferencia: ''
      }
      i++
      val = ""
    }
  }

  testLabsPDF(){

    const SESION =  JSON.parse(localStorage.getItem('usuario')).role;
    const patient = this.paciente;
    const HORA_TOMA = this.fechaToma.concat(', ' + this.horaTomaPdf);
    const HORA_LIBERACION =  this.fechaLiberacion.concat(', ' + this.horaLiberar);
    const HORA_IMPRESION = this.horaImpresion;
    const TIPO_MUESTRA = this.muestraxD;
    const REALIZO = this.realizoxD;
    const NOMBRE_ESTUDIO = this.nombre;
    const OBSERVACIONES = this.observacionesxD;
    const METODO = this.metodo;
    const DRSOLICITA = this.solicitio2
    const FECHAPEDIDOLABS = this.fechaPedidoLabs;
    const fechaPedidoCut = FECHAPEDIDOLABS.split('T')[0];

    let imgLabs = '../../../../../assets/images/hero_c.png';
    let fooLabs = '../../../../../assets/images/lineas-bs.png';
    let piensaHero = '../../../../../assets/images/piensa-l.png';
    let firmaDoc = '../../../../../assets/images/firma_doc.png';
    let marca = '../../../../../assets/images/marca_d.png';
    let qrCode = this.imgQrCode;
    
      const doc:any = new jsPDF({
      unit: "cm",
      format: 'a4',
      });

      let values: any;
      values = this.noSe.map((elemento) => Object.values(elemento));
      // var removed = values.splice(1);
      // console.log(removed);
      
      doc.autoTable({
        margin: {top:6.7 ,bottom: 6},
        theme: 'plain',
        head: [[' ', 'Elementos', 'Resultados', 'Unidades', 'Intervalo Biologico de Referencia']],
        body: values,
        didDrawCell: (values) => {
          if(values.section == 'body' && values.column.index == 0 ){
            if (values.cell.height > 1.2) {
              // console.log("entró");
              values.cell.height = 1.4
              values.row.height = 1.4
            }else if(values.cell.height < 1.2) {
              values.cell.height = 0.77
              values.row.height =0.77
            }
          }
        },
        didDrawPage: function(paciente) {
          doc.addImage(marca, 'PNG', 1.5, 12, 17.5, 7);
          if (qrCode == null || qrCode == undefined || qrCode == '' ) {
            // return
            doc.addImage(imgLabs, 'PNG', 1, 1.5, 4, 2);
            doc.addImage(fooLabs, 'PNG', 1, 26.8, 19.5, 0.4);
            doc.addImage(firmaDoc, 'PNG', 15, 23.5, 3, 4);
            doc.addImage(piensaHero, 'PNG', 17, 24.5, 3, 1);
          } else {
            doc.addImage(imgLabs, 'PNG', 1, 1.5, 4, 2);
            doc.addImage(fooLabs, 'PNG', 1, 26.8, 19.5, 0.4);
            doc.addImage(firmaDoc, 'PNG', 15, 23.5, 3, 4);
            doc.addImage(piensaHero, 'PNG', 17, 25, 3, 1);
            doc.addImage(qrCode, 'PNG', 16, 1.3, 2.5, 2.5);
          }
          doc.addImage(imgLabs, 'PNG', 1, 1.5, 4, 2);
          doc.addImage(fooLabs, 'PNG', 1, 26.8, 19.5, 0.4);
          doc.addImage(firmaDoc, 'PNG', 15, 23.5, 3, 4);
          doc.addImage(piensaHero, 'PNG', 17, 25, 3, 1);
          doc.setTextColor(0, 0, 255);
          doc.setFontSize(8);
          doc.setFont('helvetica')
          // doc.setFontType('bold')
          doc.text('PACIENTE:', 1, 4)
          doc.text(patient.nombrePaciente + ' ' + patient.apellidoPaterno + ' ' + patient.apellidoMaterno, 2.6, 4)
          doc.text('EDAD: ', 1, 4.4);
          //SETEAT EDAD
          doc.text(patient.edad + ' AÑOS', 2.6, 4.4);
          doc.text('GÉNERO: ', 1, 4.8);
          doc.text(patient.genero, 2.6, 4.8);
          doc.text('FECHA DE NACIMIENTO: ', 1, 5.2);
          // Setear Fecha paciente
          const nacimiento = String(patient.fechaNacimientoPaciente)
          if (nacimiento == 'undefined' || nacimiento == '') {
            doc.text('-- / --- / ----', 4.5, 5.2);
          }else {
            let fechaNacimiento;
            fechaNacimiento = nacimiento.slice(0, -14)
            doc.text(fechaNacimiento, 4.5, 5.2);
          }
          doc.text('SOLICITANTE: ', 1, 5.6);
          if (DRSOLICITA == undefined || DRSOLICITA == null || DRSOLICITA == '') {
            doc.text('A quien corresponda', 3.1, 5.6);
          }else{
            doc.text(DRSOLICITA, 3.1, 5.6);
          }
          doc.text('FECHA PEDIDO: ', 1, 6)
          doc.text(fechaPedidoCut, 3.3, 6);

           //Fecha y horas de muestras
           doc.text('FECHA Y HORA DE TOMA:', 12.7, 4)
           doc.text(HORA_TOMA, 16.4, 4)
           doc.text('FECHA Y HORA DE LIBERACIÓN:',12.7, 4.4)
           doc.text(HORA_LIBERACION, 17.3, 4.4)
           doc.text('HORA DE IMPRESIÓN:', 12.7, 4.8)
           doc.text(HORA_IMPRESION, 15.8, 4.8)
           doc.text('TIPO DE MUESTRA:', 12.7, 5.2)
           doc.text(TIPO_MUESTRA, 15.5, 5.2)
           doc.text('REALIZÓ: ', 12.7, 5.6)
           if (REALIZO == undefined || REALIZO == null || REALIZO == '') {
            doc.text('---- ---- ----', 14.1, 5.6)
           }else{

             doc.text('TPQC.'+ REALIZO.toUpperCase(), 14.1, 5.6)
           }
          // Nombre del estudio
            doc.setTextColor(255, 0, 0)
            doc.setFontSize(12);
            /* doc.setFontType(undefined, 'bold') */
            doc.text(NOMBRE_ESTUDIO, 7, 6.1)
            doc.addImage(fooLabs, 'PNG', 1, 6.2, 19.5, 0.5)
            doc.setTextColor(0, 0, 255);
            //Footer
            doc.setFontSize(10);
            doc.setFont('helvetica')
            doc.setFont(undefined, 'bold');
            doc.setTextColor(0, 0, 0);
            doc.text('Observaciones:', 1, 24)
            if (OBSERVACIONES == undefined || OBSERVACIONES == null  || OBSERVACIONES == '' ) {
              
            }else {
              doc.setFontSize(8);
              doc.setFont(undefined, 'bold');
              doc.setTextColor(0, 0, 0);
              doc.text(OBSERVACIONES, 1, 24.4)
            }
            doc.setTextColor(0, 0, 255);
            doc.setFontSize(10);
            doc.setFont(undefined, 'normal')
            doc.text('Método:', 4.5, 25.4);
            doc.setFontSize(8);
            doc.text(METODO, 6, 25.4)
            doc.setFontSize(8);
            doc.text('*NOTA: Apreciado paciente, gracias por su confianza, le recordamos que el resultado que le estamos', 1, 26.4)
            doc.text('entregando, solamente puede ser evaluado e interpretado por su médico tratante.', 1, 26.7)
            doc.setFontSize(10);
            doc.text('Liberó: Dr. Alberto Zamora Palma', 14.5, 26.2);
            doc.text('Cédula: 3279016', 15.5, 26.7);
        }       
      });
    this.spinner.hide();
    window.open(doc.output('bloburl', '_blank'));  
  }

  btnPrintPdf() {
    var lMargin=15; //left margin in mm
    var rMargin=15; //right margin in mm
    var pdfInMM=210;  // width of A4 in mm
    var pageCenter=pdfInMM/2;


    let imgHeader = '../../../../../assets/images/logoHeader.png';
    var marca = '../../../../../assets/images/favicon 2.png';
    var CUADROS = '../../../../../assets/images/cuadritos.png';
    var piePagina = '../../../../../assets/images/piePaginaTlaya.png'
    const patient = this.paciente;
    const NOMBRE_ESTUDIO = this.titleUsg;
      // REALIZO.toUpperCase()
    const OBSERVACIONES =this.observacionesUSG
    const IMGS =this.imgUsg
    const DIAGNOSTICOS =this.diagnosticoUsg
    const INTERPRETACION =this.interpretacion;
    const SOLICITO = this.solicitio
    const qRCode = this.imgQrCode
    const FECHAPEDIDO = this.fechaPedido;
    const fechaSplited = FECHAPEDIDO.split('T')[0];
    const realizo = this.realizoxD
    const cedula = this.cedulaUSG
    const especialidad = this.especialidadUSG
    let firmaDoc = '../../../../../assets/images/firma_doc.png';
    const doc:any = new jsPDF( {
      unit: "cm",
      format: 'a4',
    });
    
    doc.addImage(imgHeader, 'PNG', 1, 1, 6, 2);
    doc.addImage(CUADROS, 'PNG', 17, 1, 2, 2);
    doc.addImage(qRCode, 'PNG', 10, 1, 2.5, 2.5);
    doc.addImage(marca, 'PNG', 1, 6, 19, 25);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(10);
    doc.setFont('helvetica')
    
    // Ficha de identificacion
    doc.text('PACIENTE:', 1, 4)
    doc.text(patient.nombrePaciente + ' ' + patient.apellidoPaterno + ' ' + patient.apellidoMaterno, 3, 4)
    doc.text('EDAD: ', 10.5, 4);
    //SETEAT EDAD
    doc.text(patient.edad + ' AÑOS', 11.6, 4);
    doc.text('GÉNERO: ', 13.5, 4);
    doc.text(patient.genero, 15.5, 4);
    doc.text('FECHA DE NACIMIENTO: ', 1, 4.5 );
    // Setear Fecha paciente
    const nacimiento = String(patient.fechaNacimientoPaciente)
    if (nacimiento == 'undefined' || nacimiento == '') {
      doc.text('-- / --- / ----', 5.5, 4.5);
    }else {
      let fechaNacimiento;
      fechaNacimiento = nacimiento.slice(0, -14)
      doc.text(fechaNacimiento, 5.5, 4.5);
    }
    doc.text('FECHA EN QUE SE REALIZÓ: ', 1, 5 );
    doc.text( fechaSplited , 6.2, 5 );
    //  doc.text('REALIZÓ: ',  9, 4.5)
    doc.text('SOLICITÓ: ',  13.5, 4.5);
    doc.text( SOLICITO , 15.5, 4.5);
    //  doc.text(REALIZO, 9.5, 27)
    doc.text( realizo, 13, 26.6 )
    doc.text( cedula, 13, 26.9 )
    // doc.setFontSize(8)
    // doc.text( "Dra. C. Perez Toral", 1, 28.2 )
    // doc.text( "CMU-CU266", 1, 28.5)
    doc.addImage(piePagina, 1, 27, 18, 1)
    doc.setFontSize(14);
    if(NOMBRE_ESTUDIO.includes('(')){
      const newCharacter = NOMBRE_ESTUDIO.split('(');
      newCharacter[1] = "("+ newCharacter[1];
      doc.text(newCharacter[0], 6, 6)
      doc.text(newCharacter[1], 3, 6.5);
      doc.text(newCharacter[0], 6, 6)
      doc.text(newCharacter[1], 3, 6.5);
      doc.text(newCharacter[0], 6, 6)
      doc.text(newCharacter[1], 3, 6.5);
      doc.text(newCharacter[0], 6, 6)
      doc.text(newCharacter[1], 3, 6.5);
    } else if(NOMBRE_ESTUDIO.length>= 35 ){ 
      doc.text(NOMBRE_ESTUDIO, 3.5, 6)
      doc.text(NOMBRE_ESTUDIO, 3.5, 6)
      doc.text(NOMBRE_ESTUDIO, 3.5, 6)
      doc.text(NOMBRE_ESTUDIO, 3.5, 6)
      doc.text(NOMBRE_ESTUDIO, 3.5, 6)
    } else {
      doc.text(NOMBRE_ESTUDIO, 1, 6)
      doc.text(NOMBRE_ESTUDIO, 1, 6)
      doc.text(NOMBRE_ESTUDIO, 1, 6)
      doc.text(NOMBRE_ESTUDIO, 1, 6)
      doc.text(NOMBRE_ESTUDIO, 1, 6)
    }
    
    if(this.servicioValid == 'PATOLOGIA'){
      doc.text( "DESCRIPCIÓN MICROSCÓPICA:", 2.5, 8 )
      doc.setFontSize(10.8)
      doc.text( INTERPRETACION.replace(/(\r\n|\n|\r)/gm, ""), 2.5, 9, { maxWidth: 15, align: 'justify' } )
      doc.setFontSize(14)
      doc.text("DESCRIPCIÓN MACROSCÓPICA:", 2.5, 16 )
      doc.setFontSize(10.8)
      doc.text(DIAGNOSTICOS.replace(/(\r\n|\n|\r)/gm, ""), 2.5, 16.8, { maxWidth: 15, align: 'justify' })
      doc.setFontSize(14)
      doc.text('IMPRESIÓN DIAGNÓSTICA:', 2.5, 20)
      doc.setFontSize(10.8)
      doc.text(OBSERVACIONES.replace(/(\r\n|\n|\r)/gm, ""), 2.5, 20.5, { maxWidth: 15, align: 'justify' })
    }else{
      doc.text( "INTERPRETACIÓN:", 2.5, 7.5 )
      doc.setFontSize(9.5)
      console.log(INTERPRETACION);
      
      let lines = doc.setFont('helvetica')
      .setFontSize(10)
      .splitTextToSize(INTERPRETACION.toLocaleUpperCase(),19)
      var pageHeight = doc.internal.pageSize.height;
      var y = 8.5;
      for (var i = 0; i < lines.length; i++) {                
          if (y > 280) {
              y = 10;
              doc.addPage();
          }
          doc.text(3, y, lines[i]);
          y = y + .5;
      }
      doc.setFontSize(14)
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(14);
      doc.setFont('helvetica')
      y = y += 1
      doc.text("DIAGNOSTICO:", 2.5, y )
      doc.setFontSize(9.5)
      y += 1
      doc.text(DIAGNOSTICOS.toLocaleUpperCase(), 3, y, { maxWidth: 18,lineHeightFactor: 1.5, align: 'left' })
      doc.setFontSize(14)
      doc.setFontSize(14)
      y = y += 2
      if(OBSERVACIONES.length != 0){
        doc.text('OBSERVACIONES:', 2.5, y)
        y += 1
        doc.setFontSize(10.8)
        doc.text(OBSERVACIONES, 2.5, y, { maxWidth: 17, align: 'left' })
      }
      // let b = 8
      // lines.forEach(element => {
      //   b = b + .7
      //   doc.text( element,1, b,{align: 'justify',lineHeightFactor: 1.5,maxWidth:19})
      //   if(b >= 23.5){
      //     b = 7
      //     doc.text( realizo, 13, 26.6 )
      //     doc.text( cedula, 13, 26.9 )
      //     doc.addPage();
      //     doc.addImage(imgHeader, 'PNG', 1, 1, 6, 2);
      //     doc.addImage(CUADROS, 'PNG', 17, 1, 2, 2);
      //     doc.addImage(qRCode, 'PNG', 10, 1, 2.5, 2.5);
      //     doc.addImage(marca, 'PNG', 1, 6, 19, 25);
      //     doc.setTextColor(0, 0, 0);
      //     doc.setFontSize(10);
      //     doc.setFont('helvetica')
      //     // Ficha de identificacion
      //     doc.text('PACIENTE:', 1, 4)
      //     doc.text(patient.nombrePaciente + ' ' + patient.apellidoPaterno + ' ' + patient.apellidoMaterno, 3, 4)
      //     doc.text('EDAD: ', 10.5, 4);
      //     //SETEAT EDAD
      //     doc.text(patient.edad + ' AÑOS', 11.6, 4);
      //     doc.text('GÉNERO: ', 13.5, 4);
      //     doc.text(patient.genero, 15.5, 4);
      //     doc.text('FECHA DE NACIMIENTO: ', 1, 4.5 );
      //     // Setear Fecha paciente
      //     const nacimiento = String(patient.fechaNacimientoPaciente)
      //     if (nacimiento == 'undefined' || nacimiento == '') {
      //       doc.text('-- / --- / ----', 5.5, 4.5);
      //     }else {
      //       let fechaNacimiento;
      //       fechaNacimiento = nacimiento.slice(0, -14)
      //       doc.text(fechaNacimiento, 5.5, 4.5);
      //     }
      //     doc.text('FECHA EN QUE SE REALIZÓ: ', 1, 5 );
      //     doc.text( fechaSplited , 6.2, 5 );
      //     //  doc.text('REALIZÓ: ',  9, 4.5)
      //     doc.text('SOLICITÓ: ',  13.5, 4.5);
      //     doc.text( SOLICITO , 15.5, 4.5);
      //     //  doc.text(REALIZO, 9.5, 27)
      //     doc.text( realizo, 13, 26.6 )
      //     doc.text( cedula, 13, 26.9 )
      //     // doc.setFontSize(8)
      //     // doc.text( "Dra. C. Perez Toral", 1, 28.2 )
      //     // doc.text( "CMU-CU266", 1, 28.5)
      //     doc.addImage(piePagina, 1, 27, 18, 1)
      //     doc.setFontSize(14);
      //     if(NOMBRE_ESTUDIO.includes('(')){
      //       const newCharacter = NOMBRE_ESTUDIO.split('(');
      //       newCharacter[1] = "("+ newCharacter[1];
      //       doc.text(newCharacter[0], 6, 6)
      //       doc.text(newCharacter[1], 3, 6.5);
      //       doc.text(newCharacter[0], 6, 6)
      //       doc.text(newCharacter[1], 3, 6.5);
      //       doc.text(newCharacter[0], 6, 6)
      //       doc.text(newCharacter[1], 3, 6.5);
      //       doc.text(newCharacter[0], 6, 6)
      //       doc.text(newCharacter[1], 3, 6.5);
      //     } else if(NOMBRE_ESTUDIO.length>= 35 ){ 
      //       doc.text(NOMBRE_ESTUDIO, 3.5, 6)
      //       doc.text(NOMBRE_ESTUDIO, 3.5, 6)
      //       doc.text(NOMBRE_ESTUDIO, 3.5, 6)
      //       doc.text(NOMBRE_ESTUDIO, 3.5, 6)
      //       doc.text(NOMBRE_ESTUDIO, 3.5, 6)
      //     } else {
      //       doc.text(NOMBRE_ESTUDIO, 1, 6)
      //       doc.text(NOMBRE_ESTUDIO, 1, 6)
      //       doc.text(NOMBRE_ESTUDIO, 1, 6)
      //       doc.text(NOMBRE_ESTUDIO, 1, 6)
      //       doc.text(NOMBRE_ESTUDIO, 1, 6)
      //     }
      //     doc.setFontSize(10);
      //   }
      // });
      // // if(lines.length >= 29){
      // //   doc.addPage();
      // // }
      // // doc.text( INTERPRETACION, 1.5, 9, { maxWidth: 18, align:'justify' } )
      // // doc.setFontSize(14)
      // doc.setTextColor(0, 0, 0);
      // doc.setFontSize(14);
      // doc.setFont('helvetica')
      // b += 1
      // doc.text("DIAGNOSTICO:", 2.5, b )
      // doc.setFontSize(9.5)
      // b += 1
      // doc.text(DIAGNOSTICOS.toLocaleUpperCase(), 1, b, { maxWidth: 18,lineHeightFactor: 1.5, align: 'left' })
      // doc.setFontSize(14)
      // b += 2
      // if(OBSERVACIONES.length != 0){
      //   doc.text('OBSERVACIONES:', 2.5, b)
      //   b += 1
      //   doc.setFontSize(10.8)
      //   doc.text(OBSERVACIONES, 2.5, b, { maxWidth: 17, align: 'left' })
      // }
    }
    doc.setFontSize(8);
    doc.setTextColor(0,0,0);
    doc.text("Horario: Lunes a Domingo 24 horas", 1,28.1);
    doc.text("Fancisco I. Madero #27 Barrio del Rosario Tlayacapan, Mor.", 1,28.4);
    doc.text("Tel. (735) 357 7564", 1,28.7);
    doc.text("atencion.horizonte@gmail.com", 1,29);
    
    //! if de las imagenes 
    if( IMGS.length > 0 ) {
      doc.addPage();
      doc.addImage(imgHeader, 'PNG', 1.5, 1, 6, 2);
      doc.addImage(CUADROS, 'PNG', 18, 1, 2, 2);
      doc.addImage(marca, 'PNG', 1, 6, 19, 25);
      doc.addImage(qRCode, 'PNG', 10, 1, 2.5, 2.5);
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(10);
      doc.setFont('helvetica')
      doc.text('PACIENTE:', 1, 4)
      doc.text(patient.nombrePaciente + ' ' + patient.apellidoPaterno + ' ' + patient.apellidoMaterno, 3, 4)
      doc.text('EDAD: ', 10.5,4);
      //SETEAT EDAD
      doc.text(patient.edad + ' AÑOS', 11.6, 4);
      doc.text('GÉNERO: ', 13, 4);
      doc.text(patient.genero, 15, 4);
      doc.text('FECHA DE NACIMIENTO: ', 1, 4.4);
      doc.text('FECHA EN QUE SE REALIZÓ: ', 1, 5 );
      doc.text( fechaSplited , 6.2, 5 );
      doc.text( realizo, 13, 26.6 )
      doc.text( cedula, 13, 26.9 )
      doc.setFontSize(8)
      doc.addImage(piePagina, 1, 27, 18, 1)
      // doc.text(this.especialidadUSG, 10, 28.5)
      // Setear Fecha paciente
      const nacimiento = String(patient.fechaNacimientoPaciente)
      if (nacimiento == 'undefined' || nacimiento == '') {
        doc.text('-- / --- / ----', 5.5, 4.4);
      }else {
        let fechaNacimiento;
        fechaNacimiento = nacimiento.slice(0, -14)
        doc.text(fechaNacimiento, 5.5, 4.4);
      }
      doc.text('SOLICITÓ: ',  13, 4.4);
      doc.text( SOLICITO , 15, 4.4);
      doc.setFontSize(14);
      if(  NOMBRE_ESTUDIO.includes('(')){
        const newCharacter = NOMBRE_ESTUDIO.split('(');
        newCharacter[1] = "("+ newCharacter[1];
        doc.text(newCharacter[0], 6, 6);
        doc.text(newCharacter[1], 3, 6.5);
        doc.text(newCharacter[0], 6, 6)
        doc.text(newCharacter[1], 3, 6.5);
        doc.text(newCharacter[0], 6, 6)
        doc.text(newCharacter[1], 3, 6.5);
        doc.text(newCharacter[0], 6, 6)
        doc.text(newCharacter[1], 3, 6.5);
      } else if(NOMBRE_ESTUDIO.length>= 35 ){ 
        doc.text(NOMBRE_ESTUDIO, 3.5, 6)
        doc.text(NOMBRE_ESTUDIO, 3.5, 6)
        doc.text(NOMBRE_ESTUDIO, 3.5, 6)
        doc.text(NOMBRE_ESTUDIO, 3.5, 6)
        doc.text(NOMBRE_ESTUDIO, 3.5, 6)
      }
      else {
        doc.text(NOMBRE_ESTUDIO, 1, 6)
        doc.text(NOMBRE_ESTUDIO, 1, 6)
        doc.text(NOMBRE_ESTUDIO, 1, 6)
        doc.text(NOMBRE_ESTUDIO, 1, 6)
        doc.text(NOMBRE_ESTUDIO, 1, 6)
      }
      doc.text("IMAGENES: ", 1, 7);
      var countX = 2 , countY = 7.3;
      // console.log(IMGS);
      for (let index = 0; index < IMGS.length; index++) {
        if( index == 6 || index == 12 || index == 18 || index == 24){
          countX = 2, countY = 7.3;
          doc.addPage();
          doc.addImage(imgHeader, 'PNG', 1.5, 1, 4, 2);
          // doc.text(REALIZO, 9, 28)
          doc.addImage(CUADROS, 'PNG', 19, 1, 1, 1);
          doc.addImage(marca, 'PNG', 1, 6, 19, 25);
          doc.addImage(qRCode, 'PNG', 10, 1, 2.5, 2.5);
          doc.setTextColor(0, 0, 0);
          doc.setFontSize(10);
          doc.setFont('helvetica')
          doc.text('PACIENTE:', 1, 4)
          doc.text(patient.nombrePaciente + ' ' + patient.apellidoPaterno + ' ' + patient.apellidoMaterno, 3, 4)
          doc.text('EDAD: ', 9,4);
          //SETEAT EDAD
          doc.text(patient.edad + ' AÑOS', 10.5, 4);
          doc.text('GÉNERO: ', 13, 4);
          doc.text(patient.genero, 15, 4);
          doc.text('FECHA DE NACIMIENTO: ', 1, 4.4);
          doc.text('FECHA EN QUE SE REALIZÓ: ', 1, 5 );
          doc.text( fechaSplited , 6.2, 5 );
          // doc.text( "Dra. C. Perez Toral", 15, 28 )
          // doc.text( "CMU-CU266", 15, 28.5)
          if(this.servicioValid == 'PATOLOGIA'){
            doc.text('Liberó: Dr. Alberto Zamora Palma', 14.5, 26.2);
            doc.text('Cédula: 3279016', 15.5, 26.7);
            doc.addImage(firmaDoc, 'PNG', 15, 23.5, 3, 4);
          }else{
            // doc.text( realizo, 13, 26.6 )
            // doc.text( cedula, 13, 26.9 )
            // doc.setFontSize(8)
          }
          doc.addImage(piePagina, 1, 27, 18, 1)
          // Setear Fecha paciente
          const nacimiento = String(patient.fechaNacimientoPaciente)
          if (nacimiento == 'undefined' || nacimiento == '') {
            doc.text('-- / --- / ----', 5.5, 4.4);
          }else {
            let fechaNacimiento;
            fechaNacimiento = nacimiento.slice(0, -14)
            doc.text(fechaNacimiento, 5.5, 4.4);
          }
          doc.text('SOLICITÓ: ',  13, 4.4);
          doc.text( SOLICITO , 15, 4.4);
          if(  NOMBRE_ESTUDIO.includes('(')){     
            doc.setFontSize(14);
            const newCharacter = NOMBRE_ESTUDIO.split('(');
            newCharacter[1] = "("+ newCharacter[1];
            doc.text(newCharacter[0], 6, 6);
            doc.text(newCharacter[1], 3, 6.5);
            doc.text(newCharacter[0], 6, 6)
            doc.text(newCharacter[1], 3, 6.5);
            doc.text(newCharacter[0], 6, 6)
            doc.text(newCharacter[1], 3, 6.5);
            doc.text(newCharacter[0], 6, 6)
            doc.text(newCharacter[1], 3, 6.5);
          }else if(NOMBRE_ESTUDIO.length>= 35 ){ 
            doc.setFontSize(14);
            doc.text(NOMBRE_ESTUDIO, 3.5, 6)
            doc.text(NOMBRE_ESTUDIO, 3.5, 6)
            doc.text(NOMBRE_ESTUDIO, 3.5, 6)
            doc.text(NOMBRE_ESTUDIO, 3.5, 6)
            doc.text(NOMBRE_ESTUDIO, 3.5, 6)
          }else {
            doc.setFontSize(14);
            doc.text(NOMBRE_ESTUDIO, 1, 6)
            doc.text(NOMBRE_ESTUDIO, 1, 6)
            doc.text(NOMBRE_ESTUDIO, 1, 6)
            doc.text(NOMBRE_ESTUDIO, 1, 6)
            doc.text(NOMBRE_ESTUDIO, 1, 6)
          }
          doc.text("IMAGENES: ", 1, 7);
        }
        // var element = IMGS[index];
        if( index % 2  != 1 ) {
          doc.addImage(IMGS[index].base,  countX,  countY , 8, 6);
          countX = countX + 8.2;
        }else{
          doc.addImage( IMGS[index].base ,  countX,  countY , 8, 6);
          countY = countY + 6.1
          countX = 2
        }
      }
    }
    doc.setFontSize(8);
    doc.setTextColor(0,0,0);
    doc.text("Horario: Lunes a Domingo 24 horas", 1,28.1);
    doc.text("Fancisco I. Madero #27 Barrio del Rosario Tlayacapan, Mor.", 1,28.4);
    doc.text("Tel. (735) 357 7564", 1,28.7);
    doc.text("atencion.horizonte@gmail.com", 1,29);
    this.spinner.hide();
    window.open(doc.output('bloburl', '_blank'));
    // doc.save('pdf_USG.pdf')
  }
}
