import { environment } from "src/environments/environment";

//  export const URL = "https://backendpruebashorizonte.herokuapp.com"
// export let URL = "";
//export let URL = "http://localhost:3200";

// export let URL = "";

// if(environment.production){

//     URL = environment.apiUrlProdc
   

// }else{
//     URL = environment.apiUrlDev
// }
// export const URL = "https://horizonte-back-3.herokuapp.com"
//export const URL = "https://backend-pruebas-horizonte.herokuapp.com"
//export const URL = "https://horizonta.onrender.com"
//export const URL = "https://backend-api.herokuapp.com"
export const URL = "https://banco-pruebas.herokuapp.com"